import { useState, useEffect } from "react";

export const useCachedStorage = (key, initialValue, ttlHours = 48) => {
  const [value, setValue] = useState(() => {
    const stored = localStorage.getItem(key);
    if (stored) {
      const { value: storedValue, timestamp } = JSON.parse(stored);
      const now = new Date().getTime();
      const ttlMs = ttlHours * 60 * 60 * 1000;

      if (now - timestamp < ttlMs) {
        return storedValue;
      }
    }
    return initialValue;
  });

  useEffect(() => {
    const payload = {
      value,
      timestamp: new Date().getTime(),
    };
    localStorage.setItem(key, JSON.stringify(payload));
  }, [key, value]);

  return [value, setValue];
};
