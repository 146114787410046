import { UserManager, WebStorageStateStore } from "oidc-client-ts";
import { GITLAB_CLIENT_ID, GITLAB_BASE_URL } from "./constants";

const GITLAB_USER_KEY = "gitlab_user_data";

// Configuration for OIDC client
const oidcConfig = {
  authority: GITLAB_BASE_URL,
  client_id: GITLAB_CLIENT_ID,
  redirect_uri: `${window.location.origin}/callback`,
  scope: "read_api",
  automaticSilentRenew: true,
  silentRequestTimeout: 10000, // Increased timeout
  response_type: "code",
  stateStore: new WebStorageStateStore({ store: window.localStorage }),
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  metadata: {
    issuer: GITLAB_BASE_URL,
    authorization_endpoint: `${GITLAB_BASE_URL}/oauth/authorize`,
    token_endpoint: `${GITLAB_BASE_URL}/oauth/token`,
    userinfo_endpoint: `${GITLAB_BASE_URL}/api/v4/user`,
    end_session_endpoint: `${GITLAB_BASE_URL}/users/sign_out`,
  },
};

class GitLabUserManager extends UserManager {
  constructor(config) {
    super(config);
    this.setupEventHandlers();
  }

  setupEventHandlers() {
    this.events.addAccessTokenExpiring(async () => {
      try {
        await this.signinSilent();
        console.log("Token renewed successfully");
      } catch (error) {
        console.error("Silent token renewal failed:", error);
        // Optionally trigger a redirect to login
        // await this.signinRedirect();
      }
    });

    this.events.addAccessTokenExpired(async () => {
      if (document.location.pathname === "/callback") return;
      try {
        await this.signinSilent();
        console.log("Token renewed successfully");
      } catch (error) {
        console.error("Silent token renewal failed:", error);
        console.log("Token expired - redirecting to login");
        this.signinRedirect().catch((error) => console.error("Redirect after token expiration failed:", error));
      }
    });
  }

  async signinRedirectCallback(url = window.location.href) {
    const user = await super.signinRedirectCallback(url);

    if (user.access_token) {
      try {
        const userInfo = await this.fetchGitLabUserInfo(user.access_token);
        await this.storeEnhancedProfile(user, userInfo);
      } catch (error) {
        console.error("Error fetching GitLab user info:", error);
      }
    }

    return user;
  }

  async fetchGitLabUserInfo(accessToken) {
    const response = await fetch(`${GITLAB_BASE_URL}/api/v4/user`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    if (!response.ok) {
      throw new Error(`GitLab API error: ${response.statusText}`);
    }

    return response.json();
  }

  async storeEnhancedProfile(user, gitlabData) {
    const enhancedProfile = {
      ...user.profile,
      ...gitlabData,
      name: gitlabData.name,
      avatar_url: gitlabData.avatar_url,
    };

    localStorage.setItem(GITLAB_USER_KEY, JSON.stringify(enhancedProfile));
    user.profile = enhancedProfile;
  }

  async getUser() {
    const user = await super.getUser();
    if (user) {
      const storedData = localStorage.getItem(GITLAB_USER_KEY);
      if (storedData) {
        user.profile = { ...user.profile, ...JSON.parse(storedData) };
      }
    }
    return user;
  }

  async removeUser() {
    localStorage.removeItem(GITLAB_USER_KEY);
    return super.removeUser();
  }
}

export const userManager = new GitLabUserManager(oidcConfig);
