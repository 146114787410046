import { useState, useEffect, useCallback } from "react";
import { useAuth } from "../AuthContext";
import { GITLAB_BASE_URL, REFRESH_INTERVAL } from "../constants";

// Create dynamic GraphQL query based on project IDs
const createMRCheckQuery = (projectBranches) => {
  const fragments = Object.entries(projectBranches).map(
    ([projectId, branches], index) => `
    ${String.fromCharCode(97 + index)}: projects(ids: ["gid://gitlab/Project/${projectId}"]) {
      nodes {
        id
        webUrl
        fullPath
        mergeRequests(sourceBranches: ${JSON.stringify(branches)}) {
          nodes {
            id
            sourceBranch
          }
        }
      }
    }
  `,
  );

  return `query checkIfMrExists {
    ${fragments.join("\n")}
  }`;
};

export const useRecentPushes = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pushesWithoutMR, setPushesWithoutMR] = useState([]);

  const fetchPushes = useCallback(async () => {
    if (!user?.access_token || !user.profile.id) return;

    try {
      // Get yesterday's date in YYYY-MM-DD format
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      const dateStr = yesterday.toISOString().split("T")[0];

      // Fetch recent pushes with project details
      const pushResponse = await fetch(
        `${GITLAB_BASE_URL}/api/v4/users/${user.profile.id}/events?action=pushed&after=${dateStr}&per_page=50&include_project_details=true`,
        {
          headers: { Authorization: `Bearer ${user.access_token}` },
        },
      );

      if (!pushResponse.ok) {
        throw new Error(`Failed to fetch push events: ${pushResponse.status}`);
      }

      const pushEvents = await pushResponse.json();

      const pushEventsFiltered = pushEvents
        .filter((e) => e.action_name === "pushed new" || e.action_name === "pushed to")
        .filter((e) => e.push_data.ref_type === "branch");

      // Deduplicate pushes based on project_id and branch
      const uniquePushes = pushEventsFiltered.reduce((acc, event) => {
        const key = `${event.project_id}-${event.push_data.ref}`;
        // Keep the most recent push for each branch
        if (!acc[key] || new Date(event.created_at) > new Date(acc[key].created_at)) {
          acc[key] = event;
        }
        return acc;
      }, {});

      const deduplicatedPushes = Object.values(uniquePushes);

      // Group pushes by project
      const projectPushes = deduplicatedPushes.reduce((acc, event) => {
        if (!acc[event.project_id]) {
          acc[event.project_id] = [];
        }
        acc[event.project_id].push(event.push_data.ref);
        return acc;
      }, {});

      // If no pushes, return early
      if (Object.keys(projectPushes).length === 0) {
        setPushesWithoutMR([]);
        setLoading(false);
        return;
      }

      // Check for existing MRs with GraphQL query
      const query = createMRCheckQuery(projectPushes);
      const mrResponse = await fetch(`${GITLAB_BASE_URL}/api/graphql`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.access_token}`,
        },
        body: JSON.stringify({ query }),
      });

      if (!mrResponse.ok) {
        throw new Error(`Failed to fetch merge request data: ${mrResponse.status}`);
      }

      const mrResult = await mrResponse.json();

      if (mrResult.errors) {
        console.error("GraphQL Errors:", mrResult.errors);
        throw new Error("GraphQL query failed");
      }

      // Extract existing MRs and project info
      const existingMRs = new Set();
      const projectInfo = {};

      // Process GraphQL response data
      Object.values(mrResult.data || {}).forEach((projectsWrapper) => {
        if (!projectsWrapper?.nodes?.length) return;

        const project = projectsWrapper.nodes[0]; // We only query one project per alias
        if (!project) return;

        // Extract project ID from the returned GraphQL id
        const projectId = project.id.split("/").pop();

        projectInfo[projectId] = {
          webUrl: project.webUrl,
          fullPath: project.fullPath,
        };

        // Store existing MR source branches
        project.mergeRequests?.nodes?.forEach((mr) => {
          if (mr.sourceBranch) {
            existingMRs.add(mr.sourceBranch);
          }
        });
      });

      // Filter and map pushes
      const newPushes = deduplicatedPushes
        .filter(
          (event) =>
            // Exclude branches that already have MRs or are main/master
            !existingMRs.has(event.push_data.ref) && event.push_data.ref !== "main" && event.push_data.ref !== "master",
        )
        .map((event) => {
          const mappedProjectInfo = projectInfo[event.project_id];
          if (!mappedProjectInfo) {
            console.warn(`No project info found for project ID ${event.project_id}. Using fallback data.`);
          }

          return {
            ...event,
            projectInfo: mappedProjectInfo || {
              webUrl: event.project?.web_url || "",
              fullPath: event.project?.path_with_namespace || "",
            },
          };
        });

      setPushesWithoutMR(newPushes);
      setError(null);
    } catch (err) {
      console.error("Error fetching push data:", err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [user]);

  // Initial fetch
  useEffect(() => {
    if (user?.access_token) {
      fetchPushes();
    }
  }, [user, fetchPushes]);

  // Periodic refresh
  useEffect(() => {
    if (!user?.access_token) return;

    const intervalId = setInterval(fetchPushes, REFRESH_INTERVAL);
    return () => clearInterval(intervalId);
  }, [user, fetchPushes]);

  return { pushesWithoutMR, loading, error };
};
